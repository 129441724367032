import LandingSection from "./Pages/LandingSection";
import About from "./Pages/About"
import ProjectSection from "./Pages/ProjectsSection"
import Services from "./Pages/Services"
import Skills from "./Pages/Skills"
import Testimonial from "./Pages/Testimonial"
import ContactMeSection from "./Pages/ContactMeSection"
import React, {useState, useEffect} from "react"


function Home({toggleProp, toggle, setToggle, selected, setSelected}){




    return(
        <div>
          <LandingSection toggleProp={toggle} setSelected={setSelected} toggle={toggle} setToggle={setToggle} />
          <About setSelected={setSelected} selected={selected} toggleProp={toggle} setToggle={setToggle} />
          <ProjectSection setSelected={setSelected} toggleProp={toggle} setToggle={setToggle} />
          <Services setSelected={setSelected} toggleProp={toggle} setToggle={setToggle} />
          <Skills setSelected={setSelected} toggleProp={toggle} setToggle={setToggle} />
          <Testimonial setSelected={setSelected} toggleProp={toggle} setToggle={setToggle} />
          <ContactMeSection setSelected={setSelected} toggleProp={toggle} setToggle={setToggle} />
        
        </div>
    )
}



export default Home