import {Box, Text, Center,Heading, HStack, VStack} from "@chakra-ui/react"
import { useBreakpointValue } from "@chakra-ui/react"


function PrivacyPolicy({toggleProp}){

  let mobileView = useBreakpointValue({
    base: true,
    md: false
  })


  // This is to target md or tablet/ipad view
  let tabletView = useBreakpointValue({
    md: true,
    lg: false
  })



  if (mobileView){
    return(
          <div>
            
            <Box p={5} minHeight="40vh" backgroundColor={toggleProp? "#18181b":"#2A4365"} paddingBottom={10}>

              <VStack spacing={5} alignItems="start" marginLeft={1} marginRight={1}  >

                  <Center mt={5} >
                  <h4 style={{margin: 5, paddingTop: 80, fontWeight:"bold", color: "#F4CE14", fontSize: "large"}}>Current as of Oct 12, 2022</h4>
                  </Center>
                      
                  <Heading as= "h1" size="2xl" color="white" id="privacyPolicy">
                          Privacy Policy
                   </Heading><br/>

               
                <Box mt={-10}>
                    <Text fontWeight="bold" color="white" fontSize="xl" >
                    Your privacy is important to us and we are committed to protecting your personal information. This Privacy Policy
                    outlines how we collect, use and safeguard your information when you visit our website.
                    </Text>

                </Box>
                 
              </VStack>
                 
            </Box>



            
            <Box p={5} minHeight="40vh" paddingBottom={20}  backgroundColor={toggleProp && "#18181b"} color={toggleProp && "white"} >

              <VStack spacing={5} alignItems="start" marginLeft={1} marginRight={1}>
                  
                {/* <Box>

                    <Box mt={8}>
                    <Heading as= "h2" fontSize="2xl"  mt={-30}>
                    Collection of Personal Information
                    </Heading>
                    </Box>
                   
                    <Text mt={5} fontSize="large" >
                    We only collect personal information that is necessary for you to access and use our services. 
                    This may include but is not limited to your name, email address, and any other information you 
                    voluntarily provide to us. <br/><br/>
                    </Text>
 
                    
                    <Box mt={8}>
                    <Heading as= "h2" fontSize="2xl" mt={-30}>
                    Use of Personal Information
                    </Heading>
                    </Box>
                   
                    <Text mt={5} fontSize="large" >
                    We do not share, sell, rent, or provide outside access to your personal information to any third party. 
                    Any data that you provide to us is solely owned by you or your business. We may use your personal information 
                    to communicate with you, provide support, improve our services and for other internal purposes. <br/><br/>
                    </Text>


                    <Box mt={8}>
                    <Heading as= "h2" fontSize="2xl" mt={-30}>
                    Disclosure of Personal Information
                    </Heading>
                    </Box>

                    <Text mt={5} fontSize="large" >
                    We may disclose your personal information if required by law, search warrant, subpoena, court order, 
                    or fraud investigation. Additionally, we may use aggregated and anonymized data for statistical purposes, 
                    such as informing third parties about the number of visitors to our website. <br/><br/>
                    </Text>


                    <Box mt={8}>
                    <Heading as= "h2" fontSize="2xl" mt={-30}>
                    Data Security
                    </Heading>
                    </Box>

                    <Text mt={5} fontSize="large" >
                    We take appropriate measures to protect your personal information from unauthorized access, alteration, 
                    disclosure, or destruction. However, please be aware that no method of transmission over the internet or 
                    electronic storage is 100% secure and we cannot guarantee absolute security. <br/><br/>
                    </Text>


                    <Box mt={8}>
                    <Heading as= "h2" fontSize="2xl" mt={-30}>
                    Changes to Privacy Policy
                    </Heading>
                    </Box>

                    <Text mt={5} fontSize="large" >
                    If we make any changes to our Privacy Policy, we will post those changes on this page. Registered users will also 
                    be notified via email about any significant updates. By continuing to use our website after such changes, you agree 
                    to the revised Privacy Policy. <br/><br/>
                    </Text>


                    <Box mt={8} >
                    <Heading as= "h2" fontSize="2xl" mt={-30}>
                    Contact Us
                    </Heading>
                    </Box>

                    <Text mt={5} fontSize="large" >
                    If you have any questions or concerns about our Privacy Policy, please contact 
                    us at ben@benasenso.com.<br/><br/>
                    By using our website, you consent to the terms of this Privacy Policy.
                    </Text>

                </Box>  */}
                     <Box>

                    <Box mt={8}>
                    <Heading as= "h2" fontSize="2xl"  mt={-30}>
                    Collection of Personal Information
                    </Heading>
                    </Box>
                   
                    <Text mt={5} fontSize="large" >
                    We only collect personal information that is necessary for you to access and use our services. 
                    This may include but is not limited to your name, email address, and any other information you 
                    voluntarily provide to us, such as through registration forms, customer support inquiries or when you sign up
                    for newsletters or other communications. We may also collect certain technical data, including your IP address, 
                    browser type, device information, and browsing patterns to enhance your user experience.<br/><br/>
                    </Text>
 
                    
                    <Box mt={8}>
                    <Heading as= "h2" fontSize="2xl" mt={-30}>
                    Use of Personal Information
                    </Heading>
                    </Box>
                   
                    <Text mt={5} fontSize="large" >
                    We do not share, sell, rent, or provide outside access to your personal information to any third party. 
                    Any data that you provide to us is solely owned by you or your business. We may use your personal information 
                    to communicate with you, provide support, improve our services and for other internal purposes, such as managing
                    your account and conducting internal audits or troubleshooting. Additionally, we may use your data to personalize your
                    experience by providing content and offers tailored to your preferences. <br/><br/>
                    </Text>


                    <Box mt={8}>
                    <Heading as= "h2" fontSize="2xl" mt={-30}>
                    Disclosure of Personal Information
                    </Heading>
                    </Box>

                    <Text mt={5} fontSize="large" >
                    We may disclose your personal information if required by law, search warrant, subpoena, court order, 
                    or fraud investigation. Additionally, we may use aggregated and anonymized data for statistical purposes, 
                    such as informing third parties about the number of visitors to our website or usage patterns. In certain circumstances,
                    we may also disclose your information to trusted third-party service providers who assist us in operating our website,
                    conducting our business or servicing you, provided that such parties agree to keep this information confidential and comply
                    with applicable privacy laws. <br/><br/>
                    </Text>


                    <Box mt={8}>
                    <Heading as= "h2" fontSize="2xl" mt={-30}>
                    Data Retention
                    </Heading>
                    </Box>

                    <Text mt={5} fontSize="large" >
                    We retain your personal information only for as long as it is necessary to fulfill the purposes outlined 
                    in this Privacy Policy or as required by law. Once the retention period expires, we will delete or anonymize
                    your data in a secure manner to prevent unauthorized access or misuse. <br/><br/>
                    </Text>

                    <Box mt={8}>
                    <Heading as= "h2" fontSize="2xl" mt={-30}>
                    Data Security
                    </Heading>
                    </Box>

                    <Text mt={5} fontSize="large" >
                    We take appropriate measures to protect your personal information from unauthorized access, alteration, 
                    disclosure, or destruction. These measures include secure servers, firewalls, encryption of sensitive data
                    and restricted access to personal information. However, please be aware that no method of transmission over the internet or 
                    electronic storage is 100% secure and we cannot guarantee absolute security. You are responsible for maintaining
                    the confidentiality of any passwords or other security credentials related to your account.  <br/><br/>
                    </Text>


                    <Box mt={8}>
                    <Heading as= "h2" fontSize="2xl" mt={-30}>
                    Changes to Privacy Policy
                    </Heading>
                    </Box>

                    <Text mt={5} fontSize="large" >
                    If we make any changes to our Privacy Policy, we will post those changes on this page. Registered users will also 
                    be notified via email about any significant updates. We encourage you to review this Privacy Policy periodically 
                    for any updates or changes. By continuing to use our website after such changes, you agree 
                    to the revised Privacy Policy. <br/><br/>
                    </Text>


                    <Box mt={8} >
                    <Heading as= "h2" fontSize="2xl" mt={-30}>
                    Contact Us
                    </Heading>
                    </Box>

                    <Text mt={5} fontSize="large" >
                    If you have any questions, concerns or feedback about our Privacy Policy or data protection practices, please contact 
                    us at ben@benasenso.com. We will do our best to address any concerns or inquiries in a timely manner.<br/><br/>
                    By using our website, you consent to the terms of this Privacy Policy.
                    </Text>

                </Box> 
               
      
              </VStack>

                   
            </Box>        

          </div>
    )
  }

  else{

    return(
        <div>

            <Box p={5} minHeight={tabletView?"20vh": "40vh"} backgroundColor={toggleProp? "#18181b":"#2A4365"} paddingBottom={10} id="privacyPolicy">

              <VStack spacing={5} alignItems="start" marginLeft={30} paddingLeft={tabletView? 0:20} marginRight={30} paddingRight={tabletView? 0:20}>

                  <Center mt={5}  >
                  <h4 style={{margin: 5, paddingTop: 80, fontWeight:"bold", color: "#F4CE14"}}>Current as of Oct 12, 2022</h4>
                  </Center>
 
                      
                  <Heading as= "h1" size="3xl" color="white"  >
                          Privacy Policy
                   </Heading><br/>

            
                <Box mt={-4} >
                    <Text fontWeight="bold" color="white" fontSize="xl" >
                    Your privacy is important to us and we are committed to protecting your personal information. This Privacy Policy
                    outlines how we collect, use and safeguard your information when you visit our website.
                    </Text>

                </Box>
                 
              </VStack>
                 
            </Box>



            
            <Box p={5} pt={10} minHeight="40vh" paddingBottom={20}  backgroundColor={toggleProp && "#18181b"} color={toggleProp && "white"} >

              <VStack spacing={5} alignItems="start" marginLeft={30} paddingLeft={tabletView? 0:20}  marginRight={30} paddingRight={tabletView? 0:20}>
                             
                <Box>

                    <Box mt={8}>
                    <Heading as= "h2"  mt={-30}>
                    Collection of Personal Information
                    </Heading>
                    </Box>
                   
                    <Text mt={5} fontSize="xl" >
                    We only collect personal information that is necessary for you to access and use our services. 
                    This may include but is not limited to your name, email address, and any other information you 
                    voluntarily provide to us, such as through registration forms, customer support inquiries or when you sign up
                    for newsletters or other communications. We may also collect certain technical data, including your IP address, 
                    browser type, device information, and browsing patterns to enhance your user experience.<br/><br/>
                    </Text>
 
                    
                    <Box mt={8}>
                    <Heading as= "h2"  mt={-30}>
                    Use of Personal Information
                    </Heading>
                    </Box>
                   
                    <Text mt={5} fontSize="xl" >
                    We do not share, sell, rent, or provide outside access to your personal information to any third party. 
                    Any data that you provide to us is solely owned by you or your business. We may use your personal information 
                    to communicate with you, provide support, improve our services and for other internal purposes, such as managing
                    your account and conducting internal audits or troubleshooting. Additionally, we may use your data to personalize your
                    experience by providing content and offers tailored to your preferences. <br/><br/>
                    </Text>


                    <Box mt={8}>
                    <Heading as= "h2"  mt={-30}>
                    Disclosure of Personal Information
                    </Heading>
                    </Box>

                    <Text mt={5} fontSize="xl" >
                    We may disclose your personal information if required by law, search warrant, subpoena, court order, 
                    or fraud investigation. Additionally, we may use aggregated and anonymized data for statistical purposes, 
                    such as informing third parties about the number of visitors to our website or usage patterns. In certain circumstances,
                    we may also disclose your information to trusted third-party service providers who assist us in operating our website,
                    conducting our business or servicing you, provided that such parties agree to keep this information confidential and comply
                    with applicable privacy laws.  <br/><br/>
                    </Text>


                    <Box mt={8}>
                    <Heading as= "h2"  mt={-30}>
                    Data Retention
                    </Heading>
                    </Box>

                    <Text mt={5} fontSize="xl" >
                    We retain your personal information only for as long as it is necessary to fulfill the purposes outlined 
                    in this Privacy Policy or as required by law. Once the retention period expires, we will delete or anonymize
                    your data in a secure manner to prevent unauthorized access or misuse. <br/><br/>
                    </Text>



                    <Box mt={8}>
                    <Heading as= "h2"  mt={-30}>
                    Data Security
                    </Heading>
                    </Box>

                    <Text mt={5} fontSize="xl" >
                    We take appropriate measures to protect your personal information from unauthorized access, alteration, 
                    disclosure, or destruction. These measures include secure servers, firewalls, encryption of sensitive data
                    and restricted access to personal information. However, please be aware that no method of transmission over the internet or 
                    electronic storage is 100% secure and we cannot guarantee absolute security. You are responsible for maintaining
                    the confidentiality of any passwords or other security credentials related to your account. <br/><br/>
                    </Text>


                    <Box mt={8}>
                    <Heading as= "h2"  mt={-30}>
                    Changes to Privacy Policy
                    </Heading>
                    </Box>

                    <Text mt={5} fontSize="xl" >
                    If we make any changes to our Privacy Policy, we will post those changes on this page. Registered users will also 
                    be notified via email about any significant updates. We encourage you to review this Privacy Policy periodically 
                    for any updates or changes. By continuing to use our website after such changes, you agree 
                    to the revised Privacy Policy. <br/><br/>
                    </Text>


                    <Box mt={8}>
                    <Heading as= "h2"  mt={-30}>
                    Contact Us
                    </Heading>
                    </Box>

                    <Text mt={5} fontSize="xl" >
                    If you have any questions, concerns or feedback about our Privacy Policy or data protection practices, please contact 
                    us at ben@benasenso.com. We will do our best to address any concerns or inquiries in a timely manner.<br/><br/>
                    By using our website, you consent to the terms of this Privacy Policy.
                    </Text>

                </Box>
      
              </VStack>

                   
            </Box>

        </div>
    )
  }
}

export default PrivacyPolicy