import 'normalize.css'
import React from 'react';

import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ChakraProvider } from "@chakra-ui/react";
import reportWebVitals from "./reportWebVitals"


/*

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
             
            <BrowserRouter>
            <React.StrictMode>
            <App />
            </React.StrictMode>
            </BrowserRouter>


);

*/

// Used this instead of ReactDom to prevent repetitions of react-simple-chatbot
import {render}from "react-dom"
render(<BrowserRouter><App /></BrowserRouter>, document.getElementById("root"))
