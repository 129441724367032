import React, {useEffect, useState} from "react";
import {useBreakpointValue,Box,HStack, Center,Heading,Image,VStack, SimpleGrid, Card, Text, Link,CardHeader, CardBody, LinkBox, LinkOverlay} from "@chakra-ui/react";
import code from "../Assets/code.png"
import pmgt from "../Assets/pmgt.png"
import social from "../Assets/social.png"
import IT from "../Assets/IT.png"
import seo from "../Assets/seo.png"
import data from "../Assets/data.png"

import {useSpring ,animated} from "react-spring"
//import { useInView } from "react-spring";
import {useInView} from 'react-intersection-observer'





function Services({toggleProp, setToggle, setSelected}){

let[visible1, setVisible1] = useState(false)
let[visible2, setVisible2] = useState(false)
let[visible3, setVisible3] = useState(false)
let[visible4, setVisible4] = useState(false)
let[visible5, setVisible5] = useState(false)
let[visible6, setVisible6] = useState(false)



let {ref: ref1, inView:inView1} = useInView({triggerOnce: true})
let {ref: ref2, inView: inView2} = useInView({triggerOnce: true})
let {ref :ref3, inView:inView3} = useInView({triggerOnce: true})
let {ref :ref4, inView:inView4} = useInView({triggerOnce: true})
let {ref :ref5, inView:inView5} = useInView({triggerOnce: true})
let {ref :ref6, inView:inView6} = useInView({triggerOnce: true})

let {ref: refServices, inView: inViewServices} = useInView({triggerOnce: false})


let animation1 = useSpring ({
 // to: {opacity: 1, transform: "translateY(0px)"},
  //from: {opacity: 0, transform: "translateY(100px)"},
  opacity: visible1? 1:0,
  transform: visible1? "translateY(0px)": "translateY(100px)",
  config: {tension: 200, friction: 20, duration: 1000}
})


let animation2 = useSpring ({
   opacity: visible2? 1:0,
   transform: visible2? "translateY(0px)": "translateY(100px)",
   config: {tension: 200, friction: 20, duration: 1000}
 })

 
let animation3 = useSpring ({
   opacity: visible3? 1:0,
   transform: visible3? "translateY(0px)": "translateY(100px)",
   config: {tension: 200, friction: 20, duration: 1000}
 })
 
let animation4 = useSpring ({
  opacity: visible4? 1:0,
  transform: visible4? "translateY(0px)": "translateY(100px)",
  config: {tension: 200, friction: 20, duration: 1000}
})

let animation5 = useSpring ({
  opacity: visible5? 1:0,
  transform: visible5? "translateY(0px)": "translateY(100px)",
  config: {tension: 200, friction: 20, duration: 1000}
})

let animation6 = useSpring ({
  opacity: visible6? 1:0,
  transform: visible6? "translateY(0px)": "translateY(100px)",
  config: {tension: 200, friction: 20, duration: 1000}
})


useEffect((e)=>{
 
  if(inView1){
    setVisible1(true)
    }
},[inView1])


useEffect((e)=>{
 
  if(inView2){
    setVisible2(true)
    }
},[inView2])


useEffect((e)=>{
 
  if(inView3){
    setVisible3(true)
    }
},[inView3])


useEffect((e)=>{
 
  if(inView4){
    setVisible4(true)
    }
},[inView4])


useEffect((e)=>{
 
  if(inView5){
    setVisible5(true)
    }
},[inView5])


useEffect((e)=>{
 
  if(inView6){
    setVisible6(true)
    }
},[inView6])


useEffect((e)=>{

  if(inViewServices){
    setSelected("Services")
  }
},[inViewServices])






let mobileView = useBreakpointValue({
  base: true,
  md: false
})


let tabletView = useBreakpointValue({
  md: true, 
  lg: false
})

  
  return(
    <div>
        <VStack maxWidth="full" padding={10} alignItems="left" spacing={8} backgroundColor={toggleProp? "#18181b":"#2A4365"} color="white" >
          
        <Heading as="h2" size="2xl" color="white" id="services" ref={refServices}>What I Do</Heading>

      
        <Text fontSize="xl" style={{margin: 5, paddingTop: 20}}>Dive into my service offerings, your pathway to transformative solutions for your digital endeavours. Explore my expertise and discover how I can help you thrive in the digital landscape. 
        With a commitment to excellence and innovation, I provide a range of specialized services designed to empower your digital ventures.
        </Text>
    
      
      
            
            <Box width="full"  >
            
            
            <SimpleGrid columns={{base: 1, md: 2, lg:2}} gap={8}  ml={mobileView? -5: (tabletView? -10: "")} mr={mobileView? -5: (tabletView? -10: "")} paddingLeft={mobileView? 0:10} paddingRight={mobileView? 0:10}  >
              
              <animated.div style={animation1} ref={ref1} >
              {/*BoxShadow, First one is horizontal and the second is vertical */}

              <Card borderRadius={10} bg={toggleProp && "#18181b"} cursor="pointer" boxShadow= "20px 0px 20px rgba(0,0,0,0.5)" >
              <CardHeader p={8}>
                <Image style={{WebkitFilter: toggleProp && "invert(100%)"}} src={code} loading="lazy" width={24} height={24}/>      
                <Heading mt={5} color={toggleProp && "white"} >Software Development</Heading>
              </CardHeader>
       
              <CardBody p={8} pt={-8} >
              
              <Text size="3xl" color={toggleProp? "white": "#64748b"} fontSize="lg" >
              I specialize in crafting tailored software solutions to meet your unique business needs.
              Whether you're a startup looking to build your MVP or an established enterprise seeking to streamline operations, I develop
              scalable and robust software applications that drive efficiency and innovation. I create dynamic and responsive web applications using the
              latest technologies. Beyond initial development, i offer ongoing maintenance and support services to ensure your 
              software remains secure and optimized for performance.
              </Text>

              </CardBody>
            </Card>
            </animated.div>
            
            
            <animated.div style={animation2} ref={ref2} >
            <Card borderRadius={10}  bg={toggleProp && "#18181b"}  cursor="pointer"  boxShadow= "20px 0px 20px rgba(0,0,0,0.5)" >
              <CardHeader p={8}>
                <Image  src ={pmgt} loading="lazy"  style={{WebkitFilter: toggleProp && "invert(100%"}} width={24} height={24}/>
                <Heading mt={5} s color={toggleProp && "white"}>Project Management</Heading>
              </CardHeader>
       
              <CardBody p={8} pt={-8} >
              
              <Text size="3xl" color={toggleProp? "white": "#64748b"} fontSize="lg" >
              I specialize in implementing Agile methodologiesto drive efficiency and colloboration in web development projects. By braking down complex
              tasks into manageable iterations and fostering open communication, I ensure projects are delivered on time and within budget.i excel in creating
              comprehensive project plans that outline clear objectives, timelines and resource allocatios. By meticulously managing project schedules and milstones, 
              I ensure timely delivery of high quality delivearables that exceed client expectations.
              </Text>

              </CardBody>
            </Card>
            </animated.div>
            

            
            <animated.div style={animation3} ref={ref3}>
            <Card borderRadius={10} cursor="pointer" bg={toggleProp && "#18181b"}  boxShadow= "20px 0px 20px rgba(0,0,0,0.5)" >
              <CardHeader p={8}>
                <Image  src ={social} loading="lazy"  style={{WebkitFilter: toggleProp && "invert(100%)"}} width={24} height={24}/>
                <Heading mt={5}color={toggleProp && "white"} >Digital Marketing</Heading>
              </CardHeader>
       
              <CardBody p={8} pt={-8} >
              
              <Text size="3xl" color={toggleProp? "white": "#64748b"} fontSize="lg" >
             I develop customized digital marketing strategies tailored to your business goals and target audience. Whether you're looking to increase brand awareness,
             generate leads or drive sales, I leverage data-driven insights to create effective and results-driven marketing plans. I develop engaging social media strategies
             to enhance your brand presence and engage with your audience across popular platforms such as Facebook, Instagram, Twitter, LinkedIn and more. I design and execute 
             targeted email marketing campaigns to nurture leads and foster customer relationships.
              </Text>

              </CardBody>
            </Card>
            </animated.div>
          

            <animated.div style={animation4} ref={ref4}>            
            <Card borderRadius={10} cursor="pointer" bg={toggleProp && "#18181b"} boxShadow= "20px 0px 20px rgba(0,0,0,0.5)" >
              <CardHeader p={8}>
                <Image  src ={IT} loading="lazy" style={{WebkitFilter: toggleProp && "invert(100%"}} width={24} height={24}/>
                <Heading mt={5} color={toggleProp && "white"}>IT Consulting</Heading>
              </CardHeader>
       
              <CardBody p={8} pt={-8} >
              
              <Text size="3xl" color={toggleProp? "white": "#64748b"} fontSize="lg" >
              I specialize in optimizing IT Infrastructure to enhance performance, reliability and security.
              whether you're looking to streamline your network architecture, upgrade hardware and software systems
              or implement robust cybersecurity measures, I provide tailored solutions to meet your specific needs.
              I offer expert cybersecurity consulting services to protect your organization's sensitive data and assets.
              From conducting risk assessments and implementing security protocols to safeguarding your digital infrastructure 
              against evolving threats and deliver tangible results.
              </Text>

              </CardBody>
            </Card>
            </animated.div>

            
            <animated.div style={animation5} ref={ref5}>
            <Card borderRadius={10} cursor="pointer" bg={toggleProp && "#18181b"} boxShadow= "20px 0px 20px rgba(0,0,0,0.5)" >
              <CardHeader p={8}>
                <Image  src={seo} loading="lazy" style={{WebkitFilter: toggleProp && "invert(100%)"}} width={24} height={24}/>
                <Heading mt={5} color={toggleProp && "white"}>SEO Optimization</Heading>
              </CardHeader>
       
              <CardBody p={8} pt={-8} >
              
              <Text size="3xl" color={toggleProp? "white": "#64748b"} fontSize="lg" >
             I ensure that your website is fully optimized to attract organic traffic and enhance user experience. I conduct comprehensive
             technical audits to identify and fix issues that may be hindering your website's performance. I employ best practices to ensure that your
             website meets search engine standards and achieves maximum visibility. I provide in-depth analytics and reporting to track the performance 
             of your SEO efforts and measures the impact on your website's visibility and traffic. I provide actionable insights to help 
             you make informed decisions and continuously improve your SEO strategy, ensuring long term success in the ever-evolving landscape of SEO.             </Text>

              </CardBody>
            </Card>
            </animated.div>

            
            <animated.div style={animation6} ref={ref6}>
            <Card borderRadius={10} cursor="pointer" bg={toggleProp && "#18181b"} boxShadow= "20px 0px 20px rgba(0,0,0,0.5)" >
              <CardHeader p={8}>
                <Image  src={data} loading="lazy" style={{WebkitFilter: toggleProp && "invert(100%"}} width={24} height={24}/>
                <Heading mt={5} color={toggleProp && "white"}>Data Analysis</Heading>
              </CardHeader>
       
              <CardBody p={8} pt={-8} >
              
              <Text size="3xl" color={toggleProp? "white": "#64748b"} fontSize="lg" >
              Transforming raw data into actionable insights, I specialize in creating visually compelling charts, graphs
              and dashboards that help you understand your data at a glance. From simple trend analyses to complex interactive 
              visualizations, I leverage powerful tools and techniques to unlock the story hidden within your data. I provide 
              expert guidance and consultation to help you define and execute a comprehensive data strategy that aligns with your 
              business objectives. From data acquisition and storage to analysis and visualization, I work with you to develop a
              roadmap for leveraging data as a strategic asset that drives decision making and propels your business towards success.
              </Text>

              </CardBody>
            </Card>
            </animated.div>
            

         
        

            </SimpleGrid>
          

           
             

      
             </Box>




        </VStack>    
    </div>
  )
}



export default Services;
