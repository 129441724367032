import React, {useEffect, useState} from "react";
import {useBreakpointValue,Box,HStack, Center,Link,Heading,Image,VStack, SimpleGrid, Card, Text,CardHeader, CardBody, LinkBox, LinkOverlay, Button, Flex, FormHelperText, CardFooter, Avatar, Tag, TagLabel, Slide} from "@chakra-ui/react";
import bitcoin from "../Assets/bitcoin.jpg"
import stockmarket from "../Assets/stock market.jpg"
import Pavt from "../Assets/Pavt.jpeg"
import signals from "../Assets/signals.jpg"
import stocks from "../Assets/stocks.jpg"
import seo from "../Assets/seo.png"
import data from "../Assets/data.png"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight, faWarning } from "@fortawesome/free-solid-svg-icons";
import { Link as RouterLink } from "react-router-dom";
import {Formik, ErrorMessage, Form, Field} from 'formik'
import { FormControl, FormErrorMessage,  Input, Alert, AlertIcon } from "@chakra-ui/react";
import * as Yup from "yup"

import {useSpring ,animated} from "react-spring"
import {useInView} from 'react-intersection-observer'

import CookieConsent from "react-cookie-consent"


import app from '../Components/firebase'
import {getFirestore, doc, addDoc, collection} from "firebase/firestore"
import {getDatabase, onValue, ref} from "firebase/database"





function Blog({toggleProp, setToggle, setSelected}){

let [alertMessage, setAlertmessage] = useState(false)
let [alerterrormessage, setalerterrormessage] = useState(false)
let [isOpen, setIsOpen] = useState(false)
let [isOpenError, setIsOpenError] = useState(false)

let [online, setOnline] = useState(false)

let dataB = getFirestore(app)
let database = getDatabase(app)

let connectionStatus = ref(database, ".info/connected")

useEffect((e)=>{
let connectionStatusListener = onValue(connectionStatus, (snapshot)=>{
  setOnline(snapshot.val())
})

return()=>{
  connectionStatusListener()
}
}, [])



let initialValues = {
  email: ""
}

let validationSchema = Yup.object({
  email: Yup.string().email("Invalid email format").required("Email is required")
})



let onSubmit = async(values, actions)=>{

  setTimeout((e)=>{
    actions.setSubmitting(false)
  }, 30000)

  if (online){
  
  try{
    let formCollection = collection(dataB, "Newsletter")
    await addDoc(formCollection, values)
    setAlertmessage(true)
    setIsOpen(true)
    actions.resetForm()
  }
  catch(error){
    console.error("Error sending data:", error.message)
    setalerterrormessage(true)
    setIsOpenError(true)
  }
}

else{
  setalerterrormessage(true)
  setIsOpenError(true)
}

  actions.setSubmitting(false)

  setTimeout(() => {
    setIsOpen(false)
    setIsOpenError(false)
  }, 5000);  

}

let[visible1, setVisible1] = useState(false)
let[visible2, setVisible2] = useState(false)
let[visible3, setVisible3] = useState(false)
let[visible4, setVisible4] = useState(false)
let[visible5, setVisible5] = useState(false)
let[visible6, setVisible6] = useState(false)



let {ref: ref1, inView:inView1} = useInView({triggerOnce: true})
let {ref: ref2, inView: inView2} = useInView({triggerOnce: true})
let {ref :ref3, inView:inView3} = useInView({triggerOnce: true})
let {ref :ref4, inView:inView4} = useInView({triggerOnce: true})
let {ref :ref5, inView:inView5} = useInView({triggerOnce: true})
let {ref :ref6, inView:inView6} = useInView({triggerOnce: true})

let {ref: refBlog, inView: inViewBlog} = useInView({triggerOnce: false})




let animation1 = useSpring ({
 // to: {opacity: 1, transform: "translateY(0px)"},
  //from: {opacity: 0, transform: "translateY(100px)"},
  opacity: visible1? 1:0,
  transform: visible1? "translateY(0px)": "translateY(100px)",
  config: {tension: 200, friction: 20, duration: 1000}
})


let animation2 = useSpring ({
   opacity: visible2? 1:0,
   transform: visible2? "translateY(0px)": "translateY(100px)",
   config: {tension: 200, friction: 20, duration: 1000}
 })

 
let animation3 = useSpring ({
   opacity: visible3? 1:0,
   transform: visible3? "translateY(0px)": "translateY(100px)",
   config: {tension: 200, friction: 20, duration: 1000}
 })
 
let animation4 = useSpring ({
  opacity: visible4? 1:0,
  transform: visible4? "translateY(0px)": "translateY(100px)",
  config: {tension: 200, friction: 20, duration: 1000}
})

let animation5 = useSpring ({
  opacity: visible5? 1:0,
  transform: visible5? "translateY(0px)": "translateY(100px)",
  config: {tension: 200, friction: 20, duration: 1000}
})

let animation6 = useSpring ({
  opacity: visible6? 1:0,
  transform: visible6? "translateY(0px)": "translateY(100px)",
  config: {tension: 200, friction: 20, duration: 1000}
})


useEffect((e)=>{
 
  if(inView1){
    setVisible1(true)
    }
},[inView1])


useEffect((e)=>{
 
  if(inView2){
    setVisible2(true)
    }
},[inView2])


useEffect((e)=>{
 
  if(inView3){
    setVisible3(true)
    }
},[inView3])


useEffect((e)=>{
 
  if(inView4){
    setVisible4(true)
    }
},[inView4])


useEffect((e)=>{
 
  if(inView5){
    setVisible5(true)
    }
},[inView5])


useEffect((e)=>{
 
  if(inView6){
    setVisible6(true)
    }
},[inView6])


useEffect((e)=>{

  if(inViewBlog){
  setSelected("Blog")  
  }
}, [inViewBlog])






let mobileView = useBreakpointValue({
  base: true,
  md: false
})


let tabletView = useBreakpointValue({
  md: true, 
  lg: false
})

  
  return(
    <div>
        <VStack maxWidth="full" padding={10} alignItems="left" spacing={8} backgroundColor={toggleProp? "#18181b":"#2A4365"} color="white"  paddingBottom={20} >
          

        <Center>
        <h4 style={{margin: 5, paddingTop: 80, fontWeight:"bold", color: "#F4CE14"}}>Our Blog</h4>
        </Center>

        <Heading textAlign="center" as="h2" size="2xl" color="white" id="services-section" ref={refBlog} >Latest blog posts</Heading>


        <Center mt={mobileView && -5} textAlign={mobileView && "center"} >
        <h2 style={{margin: 5, paddingTop: 10, fontWeight:"bold", fontSize:mobileView && "large"}}>Catch up on what you missed or subscribe to our newsletter and never miss any news.</h2>
          </Center>

          <Center>
        <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        >

          {(formik)=>(
            <Form>

              {/*You bind items with Flex as a single unit */} 
                <Box display={!mobileView && "flex"} spacing={0} position="relative" ml={mobileView && -5} mr={mobileView && -5} >

                <FormControl isInvalid={!!formik.errors.email && formik.touched.email}>
                  <Field as={Input} name="email" id="email" width={tabletView? "55vw": (mobileView? "full": "34vw")} bg="whitesmoke" color="black" placeholder="Enter your email" />
                  <FormHelperText color="white">We care about your data in our privacy policy. <Link as={RouterLink} to="/privacy-policy" style={{color: "#F4CE14", textDecoration: "underline", justifyContent: "center"}}> Read More</Link>. </FormHelperText>
                  <FormErrorMessage>
                  <span style={{marginRight:5}}><FontAwesomeIcon icon={faWarning}/> </span>
                <ErrorMessage  name="email" /> 
                  </FormErrorMessage>
                </FormControl>
                
                <Button type="submit" mt={mobileView && 5} width={mobileView && "full"} fontWeight="bold" isLoading={formik.isSubmitting} background="#F4CE14" position="absolute" right="0" style={{zIndex:1}} >Subscribe</Button>
              
                </Box>
            </Form>

          )}

        </Formik>
            
          {alertMessage &&   
            <Slide in={isOpen} style={{marginTop: 40, zIndex: 1}} >
            <Alert status="success" mt={5}>
            <AlertIcon />
            <span style={{color:"black"}}>You have subscribed successfully to our newsletter</span>
            </Alert>

            </Slide> }

          
          {alerterrormessage && 
            <Slide in={isOpenError} style={{marginTop: 40, zIndex: 1}} >
            <Alert status="error" mt={5} >
              <AlertIcon />
              <span style={{color:"black"}}>You are offline. Please check your internet connection and try again.</span>
            </Alert>

            </Slide> }
                     

          </Center>


        <Text fontSize={mobileView? "lg" :"xl"} style={{margin: 5, marginLeft: mobileView && -10, marginRight: mobileView -10,paddingTop: mobileView? 60: 20}}> Embark on a journey of discovery with me as we delve into the intersection of finance, management and technology. From market trends to leadership insights and tech innovation, we'll 
        delve into these diverse discipline uncovering hidden connections and unlocking new perspectives along the way. Whether you're here to gain fresh perspectives or simply curious about the latest trends, I invite you to tap into the rich tapestry of ideas waiting to be discovered.
        </Text>
    
      
      
            
            <Box width="full"  >
            
            
            <SimpleGrid columns={{base: 1, md: 2, lg:2}} gap={8}  ml={mobileView? -5: (tabletView? -10: "")} mr={mobileView? -5: (tabletView? -10: "")} paddingLeft={mobileView? 0:10} paddingRight={mobileView? 0:10}  >
              
            <animated.div style={animation1} ref={ref1} >
              {/*BoxShadow, First one is horizontal and the second is vertical */}

              <Card borderRadius={10} bg={toggleProp && "#18181b"} cursor="pointer" boxShadow= "20px 0px 20px rgba(0,0,0,0.5)" >
              <CardHeader p={8} >
                <Box overflow="hidden">
                <Image src ={bitcoin} loading="lazy" _hover={{transform: "scale(1.1)", transition:"transform ease-in-out 0.3s"}} alt="Bitcoin jumps to highest since August" />      
                </Box>
                
                <HStack mt={mobileView? -4:0} >
                <Tag size={tabletView? "md": (mobileView? "sm": "lg")} transform="scale(1.4)" colorScheme="teal" margin={5} mt={14}  >
                  <TagLabel >Finance</TagLabel>
                </Tag>

                <Tag size={tabletView? "md": (mobileView? "sm" :"lg")} colorScheme="blue" transform="scale(1.4)" margin={5} mt={14} >
                  <TagLabel>Management</TagLabel>
                </Tag>
                </HStack>

              <Link as={RouterLink} to="/blog/bitcoin-jumps-to-highest-since-august"> <Heading fontSize={tabletView? "2xl": (mobileView? "2xl": "4xl")} _active={{bg: "rgba(34,167,240,0.5)", color: "red"}} mt={4} color={toggleProp &&"white"} >Bitcoin Jumps to Highest Since August</Heading> </Link>
              </CardHeader>
       
              <CardBody p={8} pt={-8} mt={-5} >
              
              <Text size="3xl" color={toggleProp? "white": "#64748b"} fontSize="lg" >
              
              Bitcoin rose to the highest level since August on Wednesday, gaining for a second day as a decrease in risk aversion helps 
              to temper concerns about a widening crackdown by US regulators.

              <Link as={RouterLink} to="/blog/bitcoin-jumps-to-highest-since-august" _active={{bg: "rgba(34,167,240,0.5)", color: "red"}} color="green" style={{justifyContent: "center"}}> Continue reading 
              <span style={{marginLeft:5}}><FontAwesomeIcon fontSize="small" icon={faAngleRight}/> </span>
              </Link>
              </Text>

              </CardBody>

              <CardFooter p={8} pt={-8} >
                <HStack>
                  <Avatar src={Pavt} name="Ben"/>
                  <VStack alignItems="start" spacing={0} color={toggleProp &&"white"}>
                    <Text margin={0} fontWeight="bold">Ben Asenso</Text>
                    <Text margin={0} >July 12, 2023</Text>
                  </VStack>
                </HStack>


              </CardFooter>
            </Card>
            </animated.div>
            
            
            <animated.div style={animation2} ref={ref2} >
              {/*BoxShadow, First one is horizontal and the second is vertical */}

              <Card borderRadius={10} bg={toggleProp && "#18181b"} cursor="pointer" boxShadow= "20px 0px 20px rgba(0,0,0,0.5)" >
              <CardHeader p={8} >
                <Box overflow="hidden">
                <Image src ={stockmarket} loading="lazy" _hover={{transform: "scale(1.1)", transition:"transform ease-in-out 0.3s"}} alt="The Bears Come Back to Bother the Bulls" />      
                </Box>
                
                <HStack mt={mobileView? -4:0} >
                <Tag size={tabletView? "md": (mobileView? "sm": "lg")} transform="scale(1.4)" colorScheme="teal" margin={5} mt={14}  >
                  <TagLabel >Finance</TagLabel>
                </Tag>

                </HStack>

              <Link as={RouterLink} to="/blog/the-bears-come-back-to-bother-the-bulls"> <Heading fontSize={tabletView? "2xl": (mobileView? "2xl": "4xl")} _active={{bg: "rgba(34,167,240,0.5)", color: "red"}} mt={4} color={toggleProp &&"white"} >The Bears Come Back to Bother the Bulls</Heading> </Link>
              </CardHeader>
       
              <CardBody p={8} pt={-8} mt={-5} >
              
              <Text size="3xl" color={toggleProp? "white": "#64748b"} fontSize="lg" >
              
              Red is dominating this week on the equity markets, which are having difficulty fully assuming their optimism regarding monetary policy. At the same time, corporate results are 
              blowing hot air, 
              <Link as={RouterLink} to="/blog/the-bears-come-back-to-bother-the-bulls" _active={{bg: "rgba(34,167,240,0.5)", color: "red"}} color="green" style={{justifyContent: "center"}}> Continue reading 
              <span style={{marginLeft:5}}><FontAwesomeIcon fontSize="small" icon={faAngleRight}/> </span>
              </Link>
              </Text>

              </CardBody>

              <CardFooter p={8} pt={-8} >
                <HStack>
                  <Avatar src={Pavt} name="Ben"/>
                  <VStack alignItems="start" spacing={0} color={toggleProp &&"white"}>
                    <Text margin={0} fontWeight="bold">Ben Asenso</Text>
                    <Text margin={0} >May 9, 2023</Text>
                  </VStack>
                </HStack>


              </CardFooter>
            </Card>
            </animated.div>
            
                
            <animated.div style={animation3} ref={ref3} >
              {/*BoxShadow, First one is horizontal and the second is vertical */}

              <Card borderRadius={10} bg={toggleProp && "#18181b"} cursor="pointer" boxShadow= "20px 0px 20px rgba(0,0,0,0.5)" >
              <CardHeader p={8} >
                <Box overflow="hidden">
                <Image src ={signals} loading="lazy" _hover={{transform: "scale(1.1)", transition:"transform ease-in-out 0.3s"}} alt="Conflicting Signals, Erratic Movements" />      
                </Box>
                
                <HStack mt={mobileView? -4:0} >
                <Tag size={tabletView? "md": (mobileView? "sm": "lg")} transform="scale(1.4)" colorScheme="teal" margin={5} mt={14}  >
                  <TagLabel >Finance</TagLabel>
                </Tag>

                </HStack>

              <Link as={RouterLink} to="/blog/conflicting-signals-erratic-movements"> <Heading fontSize={tabletView? "2xl": (mobileView? "2xl": "4xl")} _active={{bg: "rgba(34,167,240,0.5)", color: "red"}} mt={4} color={toggleProp &&"white"} >Conflicting Signals, Erratic Movements</Heading> </Link>
              </CardHeader>
       
              <CardBody p={8} pt={-8} mt={-5} >
              
              <Text size="3xl" color={toggleProp? "white": "#64748b"} fontSize="lg" >
              
              Market movements can be erratic and this reminds me of an interesting column published this week by Joachim Klement,
               a market economist from Liberum whom I quote regularly in these

              <Link as={RouterLink} to="/blog/conflicting-signals-erratic-movements" _active={{bg: "rgba(34,167,240,0.5)", color: "red"}} color="green" style={{justifyContent: "center"}}> Continue reading 
              <span style={{marginLeft:5}}><FontAwesomeIcon fontSize="small" icon={faAngleRight}/> </span>
              </Link>
              </Text>

              </CardBody>

              <CardFooter p={8} pt={-8} >
                <HStack>
                  <Avatar src={Pavt} name="Ben"/>
                  <VStack alignItems="start" spacing={0} color={toggleProp &&"white"}>
                    <Text margin={0} fontWeight="bold">Ben Asenso</Text>
                    <Text margin={0} >April 18, 2023</Text>
                  </VStack>
                </HStack>


              </CardFooter>
            </Card>
            </animated.div>
          
              
            <animated.div style={animation4} ref={ref4} >
              {/*BoxShadow, First one is horizontal and the second is vertical */}

              <Card borderRadius={10} bg={toggleProp && "#18181b"} cursor="pointer" boxShadow= "20px 0px 20px rgba(0,0,0,0.5)" >
              <CardHeader p={8} >
                <Box overflow="hidden">
                <Image src ={stocks} loading="lazy" _hover={{transform: "scale(1.1)", transition:"transform ease-in-out 0.3s"}} alt="Investor Optimism is About to Be Tested" />      
                </Box>
                
                <HStack mt={mobileView? -4:0} >
                <Tag size={tabletView? "md": (mobileView? "sm": "lg")} transform="scale(1.4)" colorScheme="teal" margin={5} mt={14}  >
                  <TagLabel >Finance</TagLabel>
                </Tag>
                <Tag size={tabletView? "md": (mobileView? "sm": "lg")} colorScheme="blue" transform="scale(1.4)" margin={5} mt={14} >
                  <TagLabel>Technology</TagLabel>
                </Tag>

                </HStack>

              <Link as={RouterLink} to="/blog/investor-optimism-is-about-to-be-tested"> <Heading fontSize={tabletView? "2xl": (mobileView? "2xl": "4xl")} _active={{bg: "rgba(34,167,240,0.5)", color: "red"}} mt={4} color={toggleProp &&"white"} >Investor Optimism is About to Be Tested</Heading> </Link>
              </CardHeader>
       
              <CardBody p={8} pt={-8} mt={-5} >
              
              <Text size="3xl" color={toggleProp? "white": "#64748b"} fontSize="lg" >
              
              Defensive stocks took their revenge yesterday, after having gone through a complicated period. By the game of 
              communicating vessels, the more audacious bets have been abandoned. 

              <Link as={RouterLink} to="/blog/investor-optimism-is-about-to-be-tested" _active={{bg: "rgba(34,167,240,0.5)", color: "red"}} color="green" style={{justifyContent: "center"}}> Continue reading 
              <span style={{marginLeft:5}}><FontAwesomeIcon fontSize="small" icon={faAngleRight}/> </span>
              </Link>
              </Text>

              </CardBody>

              <CardFooter p={8} pt={-8} >
                <HStack>
                  <Avatar src={Pavt} name="Ben"/>
                  <VStack alignItems="start" spacing={0} color={toggleProp &&"white"}>
                    <Text margin={0} fontWeight="bold">Ben Asenso</Text>
                    <Text margin={0} >October 27, 2022</Text>
                  </VStack>
                </HStack>


              </CardFooter>
            </Card>
            </animated.div>


{/*
            <animated.div style={animation5} ref={ref5}>
            <Card borderRadius={10} cursor="pointer" bg={toggleProp && "#18181b"} boxShadow= "20px 0px 20px rgba(0,0,0,0.5)" >
              <CardHeader p={8}>
                <Image  src ={seo} style={{WebkitFilter: toggleProp && "invert(100%)"}} width={24} height={24}/>
                <Heading mt={5} color={toggleProp && "white"}>SEO Optimization</Heading>
              </CardHeader>
       
              <CardBody p={8} pt={-8} >
              
              <Text size="3xl" color={toggleProp? "white": "#64748b"} fontSize="lg" >
             I ensure that your website is fully optimized to attract organic traffic and enhance user experience. I conduct comprehensive
             technical audits to identify and fix issues that may be hindering your website's performance. I employ best practices to ensure that your
             website meets search engine standards and achieves maximum visibility. I provide in-depth analytics and reporting to track the performance 
             of your SEO efforts and measures the impact on your website's visibility and traffic. I provide actionable insights to help 
             you make informed decisions and continuously improve your SEO strategy, ensuring long term success in the ever-evolving landscape of SEO.             </Text>

              </CardBody>
            </Card>
            </animated.div>

            
            <animated.div style={animation6} ref={ref6}>
            <Card borderRadius={10} cursor="pointer" bg={toggleProp && "#18181b"} boxShadow= "20px 0px 20px rgba(0,0,0,0.5)" >
              <CardHeader p={8}>
                <Image  src ={data} style={{WebkitFilter: toggleProp && "invert(100%"}} width={24} height={24}/>
                <Heading mt={5} color={toggleProp && "white"}>Data Analysis</Heading>
              </CardHeader>
       
              <CardBody p={8} pt={-8} >
              
              <Text size="3xl" color={toggleProp? "white": "#64748b"} fontSize="lg" >
              Transforming raw data into actionable insights, I specialize in creating visually compelling charts, graphs
              and dashboards that help you understand your data at a glance. From simple trend analyses to complex interactive 
              visualizations, I leverage powerful tools and techniques to unlock the story hidden within your data. I provide 
              expert guidance and consultation to help you define and execute a comprehensive data strategy that aligns with your 
              business objectives. From data acquisition and storage to analysis and visualization, I work with you to develop a
              roadmap for leveraging data as a strategic asset that drives decision making and propels your business towards success.
              </Text>

              </CardBody>
            </Card>
            </animated.div>
            
          */}
         
        

            </SimpleGrid>
          

           
             

      
             </Box>


        </VStack>  


        
        <CookieConsent expires={150} cookieName="Ben Asenso Cookie">This website uses cookies to enhance the user experience. We use cookies on our website to see how
        you interact with it. By continuing to browse the site, you agree to our use of such cookies. Read our <Link as={RouterLink} to="/privacy-policy" style={{color: "#F4CE14",fontWeight:"bold", textDecoration: "underline"}}>Privacy Policy</Link> for more information.
        </CookieConsent>
  
    </div>
  )
}



export default Blog;
