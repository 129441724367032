// firebase.js
import {initializeApp} from 'firebase/app'
//import {getFirestore} from 'firebase/firestore'
//import 'firebase/compat/firestore'

const firebaseConfig = {
  apiKey: "AIzaSyBlMwiPX825HrorbRirPZVW-RoKQRGO8zQ",
  authDomain: "little-lemon-52192.firebaseapp.com",
  databaseURL: "https://little-lemon-52192-default-rtdb.firebaseio.com",
  projectId: "little-lemon-52192",
  storageBucket: "little-lemon-52192.appspot.com",
  messagingSenderId: "1070162928684",
  appId: "1:1070162928684:web:4288b8076beff31bb09d89",
  measurementId: "G-V2LTGD1P42"
};

const app = initializeApp(firebaseConfig)
//const db = getFirestore(app)


export default app


