import React, {useEffect, useState} from "react";
import {Formik, Form, Field, ErrorMessage} from 'formik' ;
import {
  useBreakpointValue,
  Alert,
  AlertIcon,
  Slide,
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Text,
  Heading,
  Input,
  Select,
  Textarea,
  VStack,
} from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faMoon, faSun} from "@fortawesome/free-solid-svg-icons"
import {useSpring , animated } from "react-spring";
import { useInView } from "react-intersection-observer";
import * as Yup from "yup";

import app from "../Components/firebase";
import { getFirestore } from "firebase/firestore";
import {collection, addDoc} from 'firebase/firestore';
import {getDatabase, ref, onValue} from "firebase/database"



function ContactMeSection({toggleProp, toggleHandler, setToggle, setSelected}){

  //let [toggle, settoggle] = useState(false) // Creating a switch to toggle theme
  let [alertMessage, setAlertmessage] = useState("")
  let [alerterrormessage, setalerterrormessage] = useState("")

  let [online, setOnline] = useState()
  let [isOpen, setIsOpen] = useState(false)
  let [offlineMessage, setOfflineMessage] = useState(false)


  let dataB = getFirestore(app)
  let database = getDatabase(app)
  

  let onClose = ()=> {
    setIsOpen(true)
    setAlertmessage("")
  }

  let onClose2= ()=> {
    setalerterrormessage("")
  }

  //Setting the initial value for the input fields
  let initialValues = {
    name: "",
    email: "",
    message: ""
  }

  //Setting the rules for validation for the input fields
  let validationSchema = Yup.object({
    name:Yup.string().required("Name is Required"),
    email:Yup.string().email("Invalid email format").required("Email is required"),
    message: Yup.string().required("Your message is required").min(25, "Minimum characters is 25")
  })
  
  
  useEffect((e)=>{
    let connectionStatus = ref(database, ".info/connected")

    // onValue means keep an eye on the connection status, if it changes let me know and give me a picture/snapshot of it.
    let connectionStatusListener = onValue(connectionStatus, (snapshot)=>{
      setOnline(snapshot.val())
    })

    return()=>{
      connectionStatusListener()
    }
  }, [])


  
  //Setting the onSubmit handler event upon clicking submit
  // the values are the values the user typed, "setSubmitting(false)" is Formik way of saying the form submission is complete
  let onSubmit = async (values, actions) => {

    setTimeout((e)=>{
      actions.setSubmitting(false)
    }, 30000)


  if(online){

  try{
    let formsCollection = collection(dataB, "contactForms")
    await addDoc(formsCollection, values)   
    setAlertmessage(`Thanks for your submission ${values.name}, we'll get back to you shortly!`)
    actions.resetForm()
  }

  catch (error){
    setalerterrormessage("Error submitting form")
    console.error("Error submitting form:", error.message)

  }

}

else{
  setIsOpen(true)
  setOfflineMessage(true)  
  
  setTimeout(() => {
    setOfflineMessage(false)
  }, 5000);
}

  actions.setSubmitting(true)
  //I'm wrapping setTimeout around console.log and co. here bcos i want to create illusion of a loading sign.  
  setTimeout((e) => {
    console.log("Form Data:", values)

    }, 500)

} 

let [visible, setVisible] = useState(false)
let {ref: ref1, inView: inView1} = useInView({triggerOnce: true})
//let {ref :ref6, inView:inView6} = useInView({triggerOnce: true})
// useInview for contactView
let {ref: refContact, inView: inViewContact} = useInView({triggerOnce: false})


let animation = useSpring({
  opacity: visible? 1: 0,
  transform: visible? "translateY(0px)": "translateY(100px)",
  config: {tension: 200, friction: 20,duration: 1000}
})


useEffect((e)=>{
  if (inView1){
    setVisible(true)
  }
}, [inView1])

useEffect((e)=>{
  if (inViewContact){
    setSelected("Contact")
  }
}, [inViewContact])





let mobileView = useBreakpointValue({
  base: true,
  md: false
})

  
  return(
    <div>
        <VStack maxWidth="full" spacing={8} backgroundColor={toggleProp? "#18181b":"#512DA8"}color="white" >
           
           <Box marginRight="auto">
            <Heading as="h2"size="2xl" color="white" padding={10} id="contact" ref={refContact} >Contact Me</Heading>

            <Text fontSize="xl" paddingLeft={10} paddingRight={10} style={{margin: 5, paddingTop: 20,}}> Whether you're ready to kick off a project or just want to explore possibilities, get in touch to discuss your project ideas or collaboration opportunities. </Text>
            </Box>


            
            <Box width="full" paddingLeft={mobileView? 10:20} paddingRight={mobileView? 10:20} paddingBottom={20}>

            <animated.div style={animation} ref={ref1}>
            {/* Nothing should interfere the 3F's thhat is Formik, (formik) sub and Form. 
            Also sunmit button dont need onsubmit because it's part of the form*/ }

            <Formik 
              onSubmit = {onSubmit}
              initialValues = {initialValues}
              validationSchema ={validationSchema}>  
     
                {(formik) => (
               
                  
                <Form >   
                  <FormControl isInvalid = {!!formik.errors.name && formik.touched.name} maxW="full" >
                    <FormLabel htmlFor="name">Name</FormLabel>
                    <Field as={Input} type="text" id="name" name="name" placeholder="Enter your name" />
                  <FormErrorMessage>
                  <ErrorMessage name="name"/>
                  </FormErrorMessage>
                  </FormControl>

                  <FormControl isInvalid= {!!formik.errors.email && formik.touched.email} mt={4}>
                      <FormLabel htmlFor="email">Email Address</FormLabel>
                      <Field as={Input} name="email" type="email" id="email" placeholder="Enter your email address" />
                      <FormErrorMessage>
                      <ErrorMessage name="email"/>
                      </FormErrorMessage>
                  </FormControl>

                  <FormControl mt={4} >
                    <FormLabel htmlFor="select">Type of Enquiry</FormLabel>
                    <Select  id="select">
                      <option style={{color:"black"}}>Freelance Project</option>
                      <option style={{color:"black"}}>Open Consultation Session</option>
                      <option style={{color:"black"}}>Other</option>
                    </Select>
                  </FormControl>

                  <FormControl isInvalid = {!!formik.errors.message && formik.touched.message} mt={4}>
                    <FormLabel htmlFor="message">Your message</FormLabel>
                    <Field as={Textarea} name="message" id="message" minHeight={250} placeholder="Tell us about your project or message" />
                    <FormErrorMessage>
                    <ErrorMessage name= "message"/>
                    </FormErrorMessage>
                  </FormControl>
                  <Button type="submit" mt="4" width="full" colorScheme="purple" isLoading={formik.isSubmitting}>Submit</Button>
                </Form>
               )}
            </Formik>
                                        {/* The {formik.isSubmitting} is a true or false/property provided by formik that indicates whether the form is currently being submitted. That's why i have to delay the onSubmit*/}

            </animated.div>                            
          

            </Box>

            <AlertDialog
            isOpen={alertMessage}  /* What will make it open is the alertMessage variable*/
            onClose={onClose}
            >
              <AlertDialogOverlay>
              <AlertDialogContent backgroundColor= "#81C784">
              <AlertDialogHeader paddingTop={5} fontWeight="bold">
                All good!
              </AlertDialogHeader >
              <AlertDialogBody paddingBottom={5}>
                {alertMessage}<br/>
            
            </AlertDialogBody>
            </AlertDialogContent>
            </AlertDialogOverlay>

            </AlertDialog>




            <AlertDialog
            isOpen={alerterrormessage}  /* What will make it open is the alertMessage variable*/
            onClose={onClose2}
            >
              <AlertDialogOverlay>
              <AlertDialogContent backgroundColor= "#FF8A65">
              <AlertDialogHeader paddingTop={5} fontWeight="bold">
                Oops!
              </AlertDialogHeader >
              <AlertDialogBody paddingBottom={5}>
                {alerterrormessage}<br/>
            
            </AlertDialogBody>
            </AlertDialogContent>
            </AlertDialogOverlay>

            </AlertDialog>            


          
             {/*the "in" prop is what triggers or open the animation*/}
           {offlineMessage &&
          <Slide in={isOpen} >
          <Alert status="error" mt={20}>
            <AlertIcon />
            <span style={{color:"black"}}>You are offline. Please check your internet connection and try again.</span>
          </Alert> 
          </Slide>
         }
          
           
           
           {/*
            <HStack spacing={4} marginLeft="auto" marginRight={55} >
           
            <FontAwesomeIcon size="xl" icon={toggleProp? faSun: faMoon}/>
          <div className="form-check form-switch">
          <input onClick={(e)=> {
            setToggle(!toggleProp)
            setdarkAlert(!darkalert)

            setTimeout((e)=> {
              setdarkAlert(false)
            },2000)
          
          }} type="checkbox" className="form-check-input" />
          </div>
          </HStack>
        */}

        </VStack>    
    </div>
  )
}



export default ContactMeSection;
